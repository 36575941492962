<template>
  <div class="container-fluid">
    <b-tabs content-class="mt-3">
      <b-tab title="Tab1" active>
        <!-- switch with description -->
        <div class="row border-bottom my-3 pb-3">
          <h3 class="col-12">switch</h3>
          <div class="col-md-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam
          </div>
          <div class="col-md-4 text-right">
            <b-form-checkbox
              class="custom-switch"
              v-model="checked"
              name="check-button"
              switch
              size="lg"
              color="primary"
            ></b-form-checkbox>
          </div>
          <div class="col-md-8 mt-4">
            <b-form-checkbox
              class="custom-switch"
              v-model="checked"
              name="check-button"
              switch
              size="lg"
              color="primary"
            ></b-form-checkbox>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam
          </div>
          <div class="col-12"></div>
          <br />
          <div class="col-md-1 text-right">
            <b-form-checkbox
              class="custom-switch"
              v-model="checked"
              name="check-button"
              switch
              size="lg"
              color="primary"
            ></b-form-checkbox>
          </div>
          <div class="col-md-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam
          </div>
        </div>
        <!-- forms -->
        <div class="row border-bottom my-3 pb-3">
          <h3 class="col-12">Form</h3>
          <div class="col-md-8 mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Company</span>
                <input class="form-control" type="text" />
              </div>
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Type</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
                <input class="form-control" type="text" />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Fax</span>
                <input class="form-control" type="text" />
              </div>
              <div class="col-md-12 mb-2">
                <span
                  class="c-toolbar__state-title u-text-capitalize"
                >Auto CC (auto add emails to cc textbox)</span>
                <input class="form-control" type="text" />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Sales Rep</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
            </div>
          </div>
        </div>
        <!-- General -->
        <div class="row border-bottom my-3 pb-3">
          <h3 class="col-12">General</h3>
          <div class="col-md-8 mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-md-8 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Name</span>
                <input class="form-control" type="text" />
              </div>
              <div class="col-md-8 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Description</span>
                <textarea class="form-control" type="text" rows="5" />
              </div>
              <div class="col-md-8 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Group by</span>
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
            </div>
          </div>
        </div>
        <!-- Conditions -->
        <div class="row border-bottom my-3 pb-3">
          <h3 class="col-12">Conditions</h3>
          <div class="col-md-8 mb-3">
            Meet
            <strong>any</strong> of the conditions
          </div>
          <div class="col-12">
            <div class="row align-items-center mb-2" v-for="n in 3" v-bind:key="n">
              <div class="col mb-2">
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="false"></v-select>
              </div>
              <div class="col mb-2">
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="false"></v-select>
              </div>
              <div class="col mb-2">
                <v-select :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
              </div>
              <div class="col mb-2 pl-0">
                <i class="fa fa-close u-text-larger clickable u-text-danger"></i>
              </div>
            </div>
            <b-button size="sm" class="c-btn-outlined-primary">
              <i class="fa fa-plus u-mr-xsmall"></i>
              Add Condition
            </b-button>
          </div>
        </div>
        <!-- Simple Table -->
        <div class="row border-bottom my-3 pb-3">
          <h3 class="col-12">Simple table</h3>
          <div class="col-md-8 mb-3">Add or remove rows</div>
          <div class="col-8">
            <div class="table-responsive table-responsive-md w-auto dashboard-table">
              <table class="table" id="datatable">
                <thead class="c-table__head c-table__head--slim">
                  <tr class="c-table__row">
                    <th class="c-table__cell c-table__cell--head no-sort">
                      <a href="#"></a>
                    </th>
                    <th class="c-table__cell c-table__cell--head no-sort">
                      <a href="#">Field 1</a>
                    </th>
                    <th class="c-table__cell c-table__cell--head no-sort">
                      <a href="#">Field 2</a>
                    </th>
                    <th class="c-table__cell c-table__cell--head no-sort">
                      <a href="#">Field 3</a>
                    </th>
                    <th class="c-table__cell c-table__cell--head no-sort">
                      <a href="#"></a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="c-table__row" v-for="n in 2" :key="n">
                    <td class="text-center menu-icons pl-2">
                      <i class="fa fa-edit u-mr-xsmall ss-primary-font-color"></i>
                    </td>
                    <td class="align-middle">col</td>
                    <td class="align-middle">col</td>
                    <td class="align-middle">col</td>
                    <td class="text-left menu-icons pl-2">
                      <i class="fa fa-trash u-mr-xsmall ss-red-font-color"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button size="sm" class="c-btn-outlined-primary">
              <i class="fa fa-plus u-mr-xsmall"></i>
              Add Table Row
            </b-button>
          </div>
        </div>
      </b-tab>
      <b-tab title="Tab2">tab2</b-tab>
      <b-tab title="Tab3">tab3</b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "client-settings",
  components: {},
  data() {
    return {
      checked: false
    };
  },
  computed: {}
};
</script>
