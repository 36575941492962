<template>
  <div class="scrollbar sidebar-settings pt-4 u-height-100vh scrollable-div mt-0">
    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-general class="text-center">
          <i class="fa fa-th-large mt-1"></i>
          <br />
          <h6>General</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-general" accordion="my-accordion" role="tabpanel" visible>
        <!-- <b-card-body>
          <span class="d-block clickable">menu 1</span>
          <span  class="d-block clickable">menu 2</span>
          <span  class="d-block clickable">menu 3</span>
        </b-card-body>-->
      </b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-accounting class="text-center">
          <i class="fa fa-calculator mt-1"></i>
          <br />
          <h6>Accounting</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-accounting" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-business-rule class="text-center">
          <i class="fa fa-file-text mt-1"></i>
          <br />
          <h6>Business Rule</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-business-rule" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-client class="text-center">
          <i class="fa fa-users mt-1"></i>
          <br />
          <h6>Clients</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-client" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-forms class="text-center">
          <i class="fa fa-wpforms mt-1"></i>
          <br />
          <h6>Custom Forms</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-forms" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-inventory class="text-center">
          <i class="fa fa-cubes mt-1"></i>
          <br />
          <h6>Inventory</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-inventory" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-mobile class="text-center">
          <i class="fa fa-mobile mt-1"></i>
          <br />
          <h6>Mobile</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-mobile" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-navigation class="text-center">
          <i class="fa fa-list mt-1"></i>
          <br />
          <h6>Navigation</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-navigation" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-project class="text-center">
          <i class="fa fa-folder mt-1"></i>
          <br />
          <h6>Project</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-project" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-po class="text-center">
          <i class="fa fa-list-alt mt-1"></i>
          <br />
          <h6>Purchase Order</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-po" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-reports class="text-center">
          <i class="fa fa-bar-chart mt-1"></i>
          <br />
          <h6>Reports</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-reports" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-supplier class="text-center">
          <i class="fa fa-truck mt-1"></i>
          <br />
          <h6>Supplier</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-supplier" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-tasks class="text-center">
          <i class="fa fa-database mt-1"></i>
          <br />
          <h6>Support Tasks</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-tasks" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>

    <b-card no-body class="sidebar-item">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block href="#" v-b-toggle.accordion-templates class="text-center">
          <i class="fa fa-files-o mt-1"></i>
          <br />
          <h6>Templates</h6>
          <!-- <i class="fa fa-caret-down float-right mt-1"></i> -->
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-templates" accordion="my-accordion" role="tabpanel"></b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {}
};
</script>
