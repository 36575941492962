<template>
  <div class>
    <settingsSidebar></settingsSidebar>
    <settingsBody></settingsBody>
  </div>
</template>

<script>
import settingsSidebar from "@/layouts/settings-side-bar.vue";
import settingsBody from "@/layouts/settings-body.vue";

export default {
  name: "Dashboard",
  components: {
    settingsSidebar,
    settingsBody
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
