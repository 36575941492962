<template>
  <div class="content-wrapper-settings w-100 pt-4">
    <component v-bind:is="currentComponent" class="px-4"></component>
  </div>
</template>

<script>
import clientSettings from "@/views/settings/client-settings.vue";

export default {
  data() {
    return {
      currentComponent: clientSettings
    };
  },
  methods: {},
  computed: {},
  components: {
    clientSettings
  }
};
</script>
